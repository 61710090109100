/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "ProximaNovaLight";
  src: url("/extras/fonts/ProximaNova-Light.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNovaSemibold";
  src: url("/extras/fonts/ProximaNova-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "ProximaNovaExtrabold";
  src: url("/extras/fonts/ProximaNova-Extrabold.ttf") format("truetype");
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0.4em 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #e2e2e7;
  color: #e2e2e7;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #333 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #e2e2e7;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 5px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  background: #ccc;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #e6e6e6;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e85c41;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #ab0241;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #c4024b;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #c4024b;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #c4024b;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #c4024b;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 900px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #e2e2e7;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #e2e2e7;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #e2e2e7;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #e2e2e7;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #e6e6e6;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #e2e2e7;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: normal;
    font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #e2e2e7;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(196, 2, 75, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  background-color: transparent;
  color: #333;
  font-family: 'ProximaNovaLight', 'helvetica neue', helvetica, sans-serif;
  line-height: 1.57142857;
}
.cb-home {
  display: block;
  margin: 0 auto;
}
.logo {
  width: 100%;
  height: auto;
}
.cb-page-layout4 .cb-page-title {
  margin-bottom: 30px;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.header-tel,
.cb-cart-icon-holder {
  vertical-align: middle;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  line-height: 40px;
}
.header-tel {
  line-height: 1.14285714;
  padding-left: 41px;
  text-align: left;
  letter-spacing: 0.03em;
  color: #333 !important;
  background: url(/images/header-tel-28.png) no-repeat 0;
}
.header-tel__value {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  display: block;
  letter-spacing: -0.01em;
}
.cb-cart-icon-holder {
  position: relative;
  z-index: 5;
  width: 27px;
  height: 27px;
}
.cb-cart-icon-link {
  display: block;
  width: 27px;
  height: 27px;
  background: url(/images/cart-27.png) no-repeat;
}
.cb-cart-icon-count {
  font-family: 'ProximaNovaSemibold', 'helvetica neue', helvetica, sans-serif;
  line-height: 1;
  position: absolute;
  top: -0.1em;
  right: -0.6em;
  width: 1em;
  height: 1em;
  padding: 1px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background: #c00;
  font-size: 16px;
  font-size: 1.6rem;
}
.cb-cart-icon-link-empty > .cb-cart-icon-count {
  display: none;
}
html {
  -ms-overflow-style: none;
}
.cb-mood {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
.lieferung {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  width: 800px;
  max-width: 80%;
  display: none;
}
.lieferung img {
  width: 100%;
  height: auto;
}
.cb-page-selected-layout1 .lieferung {
  display: block;
}
.brotpost {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 2;
  width: 160px;
  display: none;
}
.brotpost img {
  width: 100%;
  height: auto;
}
@media (max-width: 679px) {
  .brotpost {
    right: 20px;
    top: 20px;
    width: 120px;
  }
}
.cb-page-layout1 .brotpost {
  display: block;
}
@media only screen and (min-width: 1500px) {
  .brotpost {
    right: 60px;
    width: 190px;
  }
}
.service_newsite-aspect {
  position: absolute;
  top: 0;
  right: 0;
  width: 37%;
  max-width: 207px;
}
.service_newsite {
  display: block;
  width: 100%;
  max-width: 207px;
  padding-bottom: 89.85507246%;
  background: url(/images/service_newsite.png) no-repeat 100% 0;
  background-size: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.scroll-to-content {
  /*
  should have no dimensions because of swith at layout medium
*/
  cursor: pointer;
  height: 53px;
  display: block;
  background: url(/images/scroll-to-content-48.png) no-repeat 50%;
  z-index: 20;
  opacity: 0;
  animation: appear 0.8s 2s forwards;
  -webkit-tap-highlight-color: transparent;
}
@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.maincontent {
  position: relative;
  overflow-x: hidden;
}
.cb-page-layout6 .maincontent {
  background: #ededed;
}
.cb-page-layout6 .cb-page-title {
  padding-top: 84px;
  margin-top: 40px;
  background: #fff url(/images/layout6__cb-page-title.svg) no-repeat top;
}
.fake-codepart {
  margin: 0 auto;
  max-width: 100%;
}
.aside {
  margin-top: 60px;
}
#social {
  text-align: center;
}
#social > .meta {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: inline-block;
  width: 35px;
  height: 32px;
  opacity: 0.5;
  background-repeat: no-repeat;
  background-position: 50%;
}
#social > .meta:hover,
#social > .meta:focus {
  opacity: 1;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}
#social > .meta:active {
  opacity: 0.8;
  -webkit-transition: none;
  transition: none;
}
.cb-page-selected-sub1 .service_social_facebook,
.cb-page-selected-sub2 .service_social_facebook,
.cb-page-selected-sub3 .service_social_facebook {
  background-image: url(/images/social_facebook.svg);
}
.cb-page-selected-sub1 .service_social_instagram,
.cb-page-selected-sub2 .service_social_instagram,
.cb-page-selected-sub3 .service_social_instagram {
  background-image: url(/images/social_instagram.svg);
}
.cb-page-selected-sub1 .service_social_googleplus,
.cb-page-selected-sub2 .service_social_googleplus,
.cb-page-selected-sub3 .service_social_googleplus {
  background-image: url(/images/social_googleplus.svg);
}
.cb-page-selected-sub1 #social > .mail,
.cb-page-selected-sub2 #social > .mail,
.cb-page-selected-sub3 #social > .mail {
  background-image: url(/images/social_mail.svg);
}
#external {
  text-align: center;
}
#external > .meta {
  display: inline-block;
  height: 101px;
  background-repeat: no-repeat;
  background-position: 50%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#external > .meta:after {
  background-repeat: no-repeat;
  content: '';
}
.service_external_confiseur {
  width: 74px;
}
.cb-page-selected-sub1 .service_external_confiseur,
.cb-page-selected-sub2 .service_external_confiseur,
.cb-page-selected-sub3 .service_external_confiseur {
  background-image: url(/images/external_confiseur.svg);
  background-size: 74px;
}
.service_external_confiseur:hover,
.service_external_confiseur:focus,
.aside:hover .service_external_confiseur:after {
  background-image: url(/images/external_confiseur--hover.svg);
}
.service_external_prosper {
  width: 86px;
}
.cb-page-selected-sub1 .service_external_prosper,
.cb-page-selected-sub2 .service_external_prosper,
.cb-page-selected-sub3 .service_external_prosper {
  background-image: url(/images/external_prosper-86.png);
}
.service_external_prosper:hover,
.service_external_prosper:focus,
.aside:hover .service_external_prosper:after {
  background-image: url(/images/external_prosper--hover-86.png);
}
.service_external_specialiste {
  width: 58px;
}
.cb-page-selected-sub1 .service_external_specialiste,
.cb-page-selected-sub2 .service_external_specialiste,
.cb-page-selected-sub3 .service_external_specialiste {
  background-image: url(/images/external_specialiste-58.png);
}
.service_external_specialiste:hover,
.service_external_specialiste:focus,
.aside:hover .service_external_specialiste:after {
  background-image: url(/images/external_specialiste--hover-58.png);
}
.service_external_lehrbetrieb {
  width: 79px;
}
.cb-page-selected-sub1 .service_external_lehrbetrieb,
.cb-page-selected-sub2 .service_external_lehrbetrieb,
.cb-page-selected-sub3 .service_external_lehrbetrieb {
  background-image: url(/images/external_lehrbetrieb-79.png);
}
.service_external_lehrbetrieb:hover,
.service_external_lehrbetrieb:focus,
.aside:hover .service_external_lehrbetrieb:after {
  background-image: url(/images/external_lehrbetrieb--hover-79.png);
}
.footer {
  padding: 35px 0 45px;
  color: #b3b3b3;
  background: #404040;
}
.footer a {
  /* 
applies to
– footer navi menu
– vcard links (telephone number)
– services links (artos)
*/
  color: #b3b3b3;
}
.footer a:hover,
.footer a:focus {
  color: #ececec;
}
.footer a:active {
  color: #d9d9d9;
}
.cb-goto-top {
  height: 37px;
  margin-bottom: 30px;
  cursor: pointer;
  background: url(/images/cb-goto-top.svg) no-repeat top;
}
.vcard-h2 {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
}
.vcard-h3 {
  font-family: 'ProximaNovaLight', 'helvetica neue', helvetica, sans-serif;
}
.fn,
.street-address,
.vcardemail,
.vcardurl {
  display: block;
}
.vcardemail {
  margin-top: 10px;
}
.copyright {
  float: left;
  width: 100%;
  font-size: 10px;
  font-size: 1rem;
}
.cb-page-selected-sub1 .copyright,
.cb-page-selected-sub2 .copyright,
.cb-page-selected-sub3 .copyright {
  background: url(/images/emark-alpha50.svg) no-repeat top;
  background-size: 66px;
}
.service_customimprint,
.service_datenschutz {
  margin-left: 0.4em;
}
.copyright .meta:hover,
.copyright .meta:focus {
  color: #cccccc;
}
.copyright .meta:active {
  color: #fff;
}
.service_artos {
  padding-right: 0.2em;
}
.meta.auth {
  padding-left: 0.2em;
}
.navi,
.navi > .item {
  float: left;
}
.menu {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  display: block;
  text-transform: uppercase;
}
.sub2 > .item {
  width: 100% !important;
}
div.sub2 .menu {
  text-transform: none;
}
h1,
.unit h2 {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  line-height: 1.2;
  color: #c4024b;
}
h1,
.unit h2,
.unit h3 {
  text-transform: uppercase;
}
h1 {
  text-align: center;
  letter-spacing: 0.03em;
}
.cb-page-layout6 h1 {
  color: #c4024b;
  max-width: 90%;
  margin: 0 auto;
  line-height: 1.07142857;
}
h3 {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
}
strong {
  font-weight: normal;
  font-family: 'ProximaNovaSemibold', 'helvetica neue', helvetica, sans-serif;
}
.loud {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.wide .text,
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style: disc;
}
a {
  color: #c4024b;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #dd0255;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 0.3em;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
.main {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.north {
  /*
overwrite all area rules
*/
  margin-right: 0 !important;
  margin-left: 0 !important;
}
#view .cb-page-layout4 .main {
  min-height: 0;
}
#edit .cb-page-layout4 .main {
  border-bottom: 2px dashed #ccc;
}
.cb-page-layout6 .main {
  margin-top: 0;
  background: #fff;
}
.cb-page-layout6 .side {
  padding-bottom: 40px;
}
/*
used in both Layout 1 & Layout 2
*/
.main > .pure.wide .link:only-child {
  background: none;
  margin-bottom: 70px;
}
.main > .pure.wide .link:only-child > .open {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  color: #666;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.16em;
}
.main > .pure.wide .link:only-child > .open:hover,
.main > .pure.wide .link:only-child > .open:focus {
  color: #c4024b;
}
.main > .pure.wide .link:only-child > .open:active {
  color: #a6023f;
}
.cb-page-layout1 .main > .seam.wide,
.cb-page-layout2 .main > .seam.wide,
.main > .flat.wide,
.cb-page-layout1 .main > .edge.wide,
.north > .unit {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
.cb-page-layout1 .main > .seam.wide,
.cb-page-layout2 .main > .seam.wide,
.main > .flat.wide,
.cb-page-layout1 .main > .edge.wide {
  flex-shrink: 0;
  width: 100vw !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.main > .flat.wide h2,
.cb-page-layout1 .main > .seam.wide h2,
.cb-page-layout2 .main > .seam.wide h2 {
  /*
max-width for layout2 large
*/
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 16em;
}
.cb-page-layout1 .main > .seam.wide,
.cb-page-layout2 .main > .seam.wide {
  border-bottom: solid #e0e3e5;
}
.cb-page-selected-sub1 .cb-page-layout1 .main > .seam.wide,
.cb-page-selected-sub1 .cb-page-layout2 .main > .seam.wide,
.cb-page-selected-sub2 .cb-page-layout1 .main > .seam.wide,
.cb-page-selected-sub2 .cb-page-layout2 .main > .seam.wide,
.cb-page-selected-sub3 .cb-page-layout1 .main > .seam.wide,
.cb-page-selected-sub3 .cb-page-layout2 .main > .seam.wide {
  background: #e0e3e5 url(/images/emark.svg) no-repeat bottom;
  background-size: 55px;
}
/* (nasty)
if first unit in onepage context don't double h1
*/
.cb-onepage .cb-page-layout1 .main > .seam.wide:first-child,
.cb-onepage .cb-page-layout2 .main > .seam.wide:first-child {
  margin-top: -4em;
}
/*
flat is only oversized in all Layouts
*/
.main > .flat {
  color: #fff;
  background: #334350;
}
.main > .flat a {
  color: #fff;
}
.main > .flat a:hover,
.main > .flat a:focus {
  color: #e6e6e6;
}
.main > .flat h2,
.main > .flat .loud,
.main > .flat .loud > .open {
  color: #cd8da2;
}
.main > .flat .loud.loud {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
}
.main > .flat .loud > .open:hover,
.main > .flat .loud > .open:focus {
  color: #bd6a85;
}
.main > .flat.wide .body {
  margin-top: 0.9em;
}
.main > .flat.wide .head + .body {
  margin-top: 0.4em;
}
.main > .flat .link {
  background: none;
}
.main > .flat .link > .open {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  margin: 0;
  padding: 0.3em 1.5em;
  border: 1px solid #fff;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.14em;
  background: #7c234e;
}
.main > .flat .link > .open:hover,
.main > .flat .link > .open:focus {
  color: #fff;
  background: #b6084c;
}
.main > .flat .link > .open:active {
  color: #fff;
  background: #a70f4c;
}
/*
.show has aspect in small -> large
.show is above content in small
.show is background of unit in large
*/
.cb-page-layout1 .main > .edge.wide {
  padding-top: 0;
  color: #fff;
  background: rgba(51, 0, 0, 0.9);
}
#view .cb-page-layout1 .main > .edge.wide .head {
  /*
make room for image
*/
  margin-top: 0;
  padding-top: 45%;
}
.cb-page-layout1 .main > .edge.wide .head {
  position: relative;
  z-index: 2;
}
.cb-page-layout1 .main > .edge.wide h2 {
  color: #fff;
  margin-top: 0.8em;
}
.cb-page-layout1 .main > .edge.wide h3 {
  text-transform: none;
  font-family: 'ProximaNovaLight', 'helvetica neue', helvetica, sans-serif;
}
#view .cb-page-layout1 .main > .edge.wide .show {
  /*
new design: show full image
= original design image aspect from small to large
*/
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 0;
  margin: 0;
  padding-bottom: 45%;
}
#view .cb-page-layout1 .main > .edge.wide .show {
  overflow: hidden;
}
#view .cb-page-layout1 .main > .edge.wide .show img {
  position: absolute;
  top: 0;
  right: 0;
  max-width: none !important;
  max-height: none !important;
  object-fit: cover;
}
.cb-page-layout3 .main h3,
.cb-page-layout3 .main .loud {
  text-transform: none;
  line-height: 1.5;
  font-family: 'ProximaNovaLight', 'helvetica neue', helvetica, sans-serif;
}
.cb-page-layout3 .main > .seam .body,
.cb-page-layout3 .main > .seam .show {
  margin: 0;
}
.cb-page-layout3 .main > .seam .link {
  /*
has same font-size as h2s
*/
  background: none;
}
#view .cb-page-layout3 .main > .seam .link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: 0;
  overflow: hidden;
}
.cb-page-layout3 .main > .seam .link > .open {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  font-size: 28px;
  font-size: 2.8rem;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
}
#view .cb-page-layout3 .main > .seam .link > .open {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin-top: -1em;
  padding-top: 36%;
  -webkit-transition: background 0.218s;
  transition: background 0.218s;
  background: rgba(0, 0, 0, 0.4);
}
#view .cb-page-layout3 .main > .seam .link > .open:hover,
#view .cb-page-layout3 .main > .seam .link > .open:focus {
  -webkit-transition: none;
  transition: none;
  mix-blend-mode: screen;
  background: rgba(196, 2, 75, 0.4);
  text-shadow: 0 0 8px rgba(255, 0, 0, 0.92), 0px 3px 2px rgba(255, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.3), 0 0 2px #fff;
}
#view .cb-page-layout3 .main > .seam .link > .open:active {
  background: rgba(115, 1, 44, 0.8);
  text-shadow: none;
}
.cb-page-layout3 .main > .edge {
  /*
unit font
*/
  line-height: 1.28571429;
}
.cb-page-layout3 .main > .edge .norm {
  max-width: 58em;
}
.cb-page-layout3 .main > .edge h3 {
  /*
same size as h2 in large
*/
  color: #c4024b;
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.015em;
}
.cb-page-layout3 .main > .edge .tiny > .norm > strong {
  /*
'Öffnungszeiten' (Filialen)
*/
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
}
.cb-page-layout3 .main > .edge .tiny.link {
  background: none;
}
.cb-page-layout3 .main > .edge .link > .open {
  color: #fff;
  background: #b3b3b3;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  margin: 0;
  text-align: center;
  display: inline-block;
  padding: 1em;
  width: 14em;
}
.cb-page-layout3 .main > .edge .link > .open:hover,
.cb-page-layout3 .main > .edge .link > .open:focus {
  background: #808080;
}
.cb-page-layout3 .main > .edge .link > .open:active {
  background: #4d4d4d;
}
.cb-page-layout4 .side > .unit,
.cb-page-layout4 .base > .unit {
  letter-spacing: 0.03em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  line-height: 1.2;
  border: 1px solid #9898ad;
  outline-offset: -2px;
  outline: 3px solid rgba(0, 0, 51, 0.1);
}
.cb-page-layout4 .side h2,
.cb-page-layout4 .base h2 {
  margin-top: 1em;
  font-size: 1em;
}
.cb-page-layout4 .side .norm > strong,
.cb-page-layout4 .base .norm > strong {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
}
.cb-page-layout4 .side .show.tall,
.cb-page-layout4 .base .show.tall {
  width: 100% !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: -16%;
}
.cb-page-layout4 .side .show .cb-portrait,
.cb-page-layout4 .side .show .cb-landscape,
.cb-page-layout4 .base .show .cb-portrait,
.cb-page-layout4 .base .show .cb-landscape {
  margin-right: auto;
  margin-left: auto;
}
.cb-page-layout4 .side > .seam h2,
.cb-page-layout4 .base > .seam h2,
.cb-page-layout4 .side > .seam .text,
.cb-page-layout4 .base > .seam .text {
  position: relative;
  z-index: 2;
}
.cb-page-layout4 .side > .seam h2,
.cb-page-layout4 .base > .seam h2 {
  text-align: left;
  margin-top: 0.8em;
  max-width: 58%;
  line-height: 1.25;
  letter-spacing: 0.02em;
}
.cb-page-layout4 .side > .seam:after,
.cb-page-layout4 .base > .seam:after {
  font-size: 0;
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -1px;
  width: 50%;
  max-width: 185px;
  height: 0;
  padding-bottom: 40%;
  content: 'Empfehlung';
  background: url(/images/empfehlung-185.png) no-repeat 100% 0;
  background-size: contain;
}
.cb-page-layout4 .side > .seam .part.payp:last-child,
.cb-page-layout4 .base > .seam .part.payp:last-child {
  margin-bottom: 0;
}
.cb-page-layout4 .text + .payp {
  margin-top: -0.4em;
}
.payp > .name.name {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  padding-bottom: 0.6em;
}
.payp__step {
  position: relative;
  float: left;
  /*
element created with JS
*/
  overflow: hidden;
  margin-top: 6px;
  height: 23px;
  border: 1px solid #bcbcbc;
}
.payp__step > .minus,
.payp__step > .plus,
.payp__step > .numb {
  color: #333;
  vertical-align: bottom;
  font-family: 'ProximaNovaSemibold', 'helvetica neue', helvetica, sans-serif;
  border-radius: 0 !important;
  box-shadow: none !important;
  height: 20px;
  border: 0;
  background: none;
  float: none !important;
  vertical-align: middle;
  display: inline-block;
}
.payp__step > .numb {
  position: relative;
  top: -4px;
}
.payp__step > .minus,
.payp__step > .plus {
  font-size: 17px;
  font-size: 1.7rem;
  width: 1.2em;
  cursor: pointer;
  text-align: center;
  border: solid #bcbcbc;
  border-width: 0 1px;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: -1px;
  height: 32px;
  line-height: 23px;
}
.payp__step > .minus:hover,
.payp__step > .plus:hover,
.payp__step > .minus:focus,
.payp__step > .plus:focus {
  color: #000;
  background: #eee;
}
.payp__step > .minus:active,
.payp__step > .plus:active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e7e7e7), to(#d6d5d7));
  background-image: -webkit-linear-gradient(top, #e7e7e7, #d6d5d7);
  background-image: linear-gradient(top, #e7e7e7, #d6d5d7);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 -1px 1px rgba(255, 255, 255, 0.28) inset;
}
.payp__step > .plus {
  line-height: 27px;
}
.payp__step > .numb {
  font-size: 15px;
  font-size: 1.5rem;
}
.payp__step > .numb::-webkit-inner-spin-button,
.payp__step > .numb::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.payp input.numb:focus {
  text-align: left;
}
.ctrl > .payp.payp {
  padding-right: 0;
}
.payp input.payp {
  margin-top: -7px;
  display: block;
}
.payp .ctrl {
  margin-top: 0.3em;
}
.part.payp .prix,
.part.payp .cury {
  color: #c4024b;
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  text-transform: uppercase;
}
#view .part.payp .prix {
  float: right;
  margin-left: 0.3em;
  margin-right: 0.3em;
}
#view .part.payp .cury {
  margin-left: 0;
  float: left;
}
.cb-page-layout6 .main > .unit,
.cb-page-layout6 .main > .unit h2,
.cb-page-layout6 .main > .unit a {
  color: #c4024b;
}
.cb-page-layout6 .main > .unit {
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.25;
}
#view .cb-page-layout6 .main > .unit {
  float: none;
  max-width: 90%;
  margin-right: auto !important;
  margin-left: auto !important;
}
.cb-page-layout6 .main > .unit .part:first-child {
  /*
text after 'Öffnungszeiten'
*/
  margin-top: 0;
}
.cb-page-layout6 .main .loud {
  text-transform: uppercase;
}
.cb-page-layout6 .side > .unit {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.27777778;
}
.cb-page-layout6 .side h2 {
  text-transform: uppercase;
}
.cb-page-layout6 .side h2 > strong,
.cb-page-layout6 .side h2 > .open {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  color: #333;
}
.north {
  color: #fff;
}
.north h2 {
  color: #fff;
}
.north .body {
  margin-top: 0;
}
.north a,
.cb-page-layout1 .main > .edge.wide a {
  color: #fff;
}
.north a:hover,
.cb-page-layout1 .main > .edge.wide a:hover,
.north a:focus,
.cb-page-layout1 .main > .edge.wide a:focus {
  color: #e6e6e6;
}
.north h3 {
  color: #fff;
  font-family: 'ProximaNovaLight', 'helvetica neue', helvetica, sans-serif;
  text-transform: none;
  line-height: 1.04166667;
}
.north .link {
  background: none;
}
.north .link > .open {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  margin: 0;
  border: 1px solid #fff;
  text-transform: uppercase;
  display: inline-block;
  background: rgba(196, 2, 75, 0.5);
}
.north .link > .open:hover,
.north .link > .open:focus {
  color: #fff;
  background: rgba(196, 2, 75, 0.6);
}
.north .link > .open:active {
  color: #fff;
  background: rgba(135, 1, 52, 0.55);
}
.cb-page-layout6 .main > .unit {
  margin-top: 14px;
}
.cb-page-layout6 .main .body {
  margin-top: 0;
}
.cb-page-layout6 .main.main h2 {
  font-size: 24px;
  font-size: 2.4rem;
}
.cb-page-layout6 .side h2 {
  /*
'Post Parc'
*/
  margin-right: 0 !important;
}
.cb-page-layout6 .side h2 strong {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.cb-page-layout6 .side .body,
.cb-page-layout6 .side .part:first-child {
  margin-top: 0;
}
/* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */
#mc_embed_signup form {
  font-size: 0;
  white-space: nowrap;
  position: relative;
  display: block;
  padding: 10px 0 10px 3%;
  text-align: left;
}
#mc_embed_signup input {
  font-family: 'ProximaNovaLight', 'helvetica neue', helvetica, sans-serif;
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  text-transform: uppercase;
  border: 1px solid #000;
}
#mc_embed_signup input:focus {
  border-color: #333;
  position: relative;
  z-index: 1;
}
#mc_embed_signup .button {
  font-weight: normal;
  display: inline-block;
  clear: both;
  width: auto;
  margin: 0 5px 10px 0;
  cursor: pointer;
  transition: all 0.23s ease-in-out 0s;
  text-align: center;
  vertical-align: top;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  border: 1px solid #000;
  margin-left: -1px;
  background-color: #6b0529;
  -webkit-appearance: none;
}
#mc_embed_signup .button:hover,
#mc_embed_signup .button:focus {
  transition: none;
  background-color: #c4024b;
}
#mc_embed_signup .button:active {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset;
}
#mc_embed_signup .small-meta {
  font-size: 11px;
}
#mc_embed_signup .nowrap {
  white-space: nowrap;
}
#mc_embed_signup .mc-field-group {
  position: relative;
  margin-left: -1px;
  min-height: 50px;
  padding-bottom: 3%;
}
#mc_embed_signup .mc-field-group,
#mc_embed_signup .button {
  line-height: 15px;
  white-space: normal;
  font-size: 13px;
  display: inline-block;
  vertical-align: top;
}
#mc_embed_signup .size1of2 {
  display: inline-block;
  float: left;
  clear: none;
  width: 46%;
  margin-right: 4%;
}
#mc_embed_signup .mc-field-group label {
  display: block;
  margin-bottom: 3px;
}
#mc_embed_signup .mc-field-group input,
#mc_embed_signup .button {
  padding: 8px 0;
  border-radius: 0;
  -webkit-appearance: none;
}
#mc_embed_signup .mc-field-group input {
  display: block;
  width: 100%;
  text-indent: 2%;
}
#mc_embed_signup .indicates-required {
  font-size: 11px;
  margin-right: 4%;
  text-align: right;
}
#mc_embed_signup .asterisk {
  font-size: 150%;
  font-weight: normal;
  position: relative;
  top: 5px;
  color: #e85c41;
}
#mc_embed_signup .clear {
  clear: both;
}
#mc_embed_signup .mc-field-group.input-group ul {
  margin: 0;
  padding: 5px 0;
  list-style: none;
}
#mc_embed_signup .mc-field-group.input-group ul li {
  display: block;
  margin: 0;
  padding: 3px 0;
}
#mc_embed_signup .mc-field-group.input-group label {
  display: inline;
}
#mc_embed_signup .mc-field-group.input-group input {
  display: inline;
  width: auto;
  border: none;
}
#mc_embed_signup div#mce-responses {
  white-space: normal;
  top: -1.4em;
  font-size: 14px;
  float: left;
  clear: both;
  overflow: hidden;
  width: 100%;
  margin: 0;
}
#mc_embed_signup div.response {
  font-weight: bold;
  z-index: 1;
  top: -1.5em;
  float: left;
  width: 90%;
  margin: 0;
  padding: 1em 5% 60px;
  border-radius: 4px;
  background: #eee url(/images/emark.svg) no-repeat bottom;
  background-size: 40px;
  border-bottom: 20px solid #eee;
}
#mc_embed_signup #mce-error-response {
  display: none;
}
#mc_embed_signup #mce-success-response {
  display: none;
  color: #529214;
}
#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  padding: 0.5em 0;
  text-align: left;
}
#mc-embedded-subscribe {
  display: block;
  clear: both;
  width: auto;
  margin: 1em 0 1em 5%;
}
#mc_embed_signup #num-subscribers {
  font-size: 1.1em;
}
#mc_embed_signup #num-subscribers span {
  font-weight: bold;
  margin-right: 0.5em;
  padding: 0.5em;
  border: 1px solid #ccc;
}
#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  font-size: 14px;
  font-weight: normal;
  z-index: 1;
  display: inline-block;
  margin: 2px 0 1em 0;
  padding: 5px 10px;
  color: #e85c41;
  background-color: rgba(255, 255, 255, 0.85);
}
#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
  border-color: #e85c41;
  box-shadow: 0 0 0 1px #e85c41 inset;
}
.grow {
  text-transform: none !important;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #c4024b;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #c4024b;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/******* forms.less 2016-01-05 *******/
/* Changelog

2016-01-05
added Calendar (partial copy of manage.css)

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 2.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.11666667em 3px;
  min-height: 2.2em;
  border: 1px solid #ccc;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 12px;
  font-size: 1.2rem;
  color: #444;
  font-weight: normal;
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #d9d9d9;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.11666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #ab0241 #78012e #78012e #ab0241;
  border-radius: 2px;
  background-color: #c4024b;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(95, 1, 36, 0.75);
  line-height: 2.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #ab0241 #78012e #78012e #ab0241;
  background-color: #d30251;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #ab0241 #78012e #78012e #ab0241;
  background-color: #920138;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.calendar {
  line-height: 1.5;
  position: absolute;
  z-index: 100;
  text-align: right;
  border: 3px solid #666;
  background: #999;
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  font-weight: 700;
  text-align: center;
  color: #eee;
}
.calendar tbody {
  color: #999;
  background-color: #eee;
}
td.show {
  font-weight: 700;
  color: #fff;
  background: #7be;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px;
  text-decoration: none;
  color: inherit;
}
.calendar th > a:hover,
.calendar td > a:hover,
.calendar th > a:focus,
.calendar td > a:focus {
  color: #fff;
  background: #ccc;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 2.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef8f7 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e85c41;
  color: #e85c41 !important;
}
div.fail {
  background: #fef8f7 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e85c41;
  border-style: solid none;
  color: #e85c41;
}
div.cb-lightbox-table ul.fail {
  background: #fef8f7 url('/icons/warning.png') no-repeat 99% !important;
  color: #e85c41;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.togglenavigation {
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.togglenavigation__outer {
  width: 24px;
  height: 17px;
  display: inline-block;
  position: relative;
}
.togglenavigation__inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.togglenavigation__inner,
.togglenavigation__inner::before,
.togglenavigation__inner::after {
  width: 24px;
  height: 3px;
  background-color: #030303;
  position: absolute;
}
.togglenavigation__inner::before,
.togglenavigation__inner::after {
  content: "";
  display: block;
}
.togglenavigation__inner::before {
  top: -7px;
}
.togglenavigation__inner::after {
  bottom: -7px;
}
/*
 * Squeeze
 */
/*
 * Sideways
 */
.togglenavigation--sideways.cb-toggle-active {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(90deg);
  transform: translate3d(-8px, 0, 0) rotate(90deg);
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.14s;
  transition-timing-function: ease;
}
.togglenavigation--sideways.cb-toggle-active .togglenavigation__inner,
.togglenavigation--sideways.cb-toggle-active .togglenavigation__inner::before,
.togglenavigation--sideways.cb-toggle-active .togglenavigation__inner::after {
  background: #fff;
  width: 23px;
}
/******* layout-small.less 2013-1-16 *******/
.cb-home {
  width: 80%;
  max-width: 224px;
  margin-top: 71px;
  position: relative;
  left: 10px;
}
.header-tel,
.header-services .auth,
.togglenavigation,
.service_newsite,
.north .link > .open,
.main > .flat.wide .link > .open,
.cb-page-layout3 .main > .seam .link > .open,
.cb-cart-icon-link,
#social > .meta,
#external > .meta,
.payp__step > .minus,
.payp__step > .plus {
  -webkit-tap-highlight-color: transparent;
}
.cb-mobile .payp__step > .numb {
  touch-action: manipulation;
  pointer-events: none;
}
.cb-home {
  -webkit-tap-highlight-color: rgba(153, 153, 153, 0.05);
}
.cb-mobile #social > .meta:active {
  padding-bottom: 30px;
  margin-bottom: -30px;
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  -o-transform: translateY(-30px);
  transform: translateY(-30px);
}
.desk {
  width: 90%;
  max-width: 432px;
}
.header > .desk {
  position: relative;
}
.cb-mood > .desk {
  max-width: none;
  width: 100%;
}
.header-tel {
  display: none;
}
.cb-cart-icon-holder {
  float: left;
  margin-top: 4px;
}
@media (max-width: 18em) {
  .header-tel {
    padding-left: 32px;
  }
  .header-tel__text {
    display: none;
    line-height: inherit;
  }
}
@media (max-width: 679px) {
  .togglenavigation {
    right: 0;
    top: 6px;
    width: 24px;
    height: 17px;
    cursor: pointer;
    display: block;
    position: absolute;
    color: #fff !important;
    z-index: 5;
  }
  .togglenavigation.cb-toggle-active {
    top: 11px;
    right: 8px;
    position: fixed;
  }
  .navigation.cb-toggle-target-active {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    display: block;
    z-index: 4;
    width: 219px;
  }
  .navigation.cb-toggle-target-active > .desk {
    width: 100%;
    max-width: none;
  }
  div.sub1 {
    display: none;
  }
  .navigation.cb-toggle-target-active .sub1 {
    width: 100%;
    overflow: scroll;
    background: #000;
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    padding-bottom: 40px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
  .navigation.cb-toggle-target-active .sub1::-webkit-scrollbar {
    display: none;
  }
  .navi > .item {
    width: 100%;
    position: relative;
  }
  .sub1 > .init {
    margin-top: 73px;
  }
  .menu {
    font-size: 18px;
    font-size: 1.8rem;
    color: #999;
    text-transform: none;
    padding: 0.32em 0 0.33em 23px;
    -webkit-tap-highlight-color: transparent;
  }
  .menu:hover,
  .menu:focus,
  .menu.path {
    color: #fff;
  }
  .item > .cb-toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 2.7em;
    background: url(/images/cb-toggle--down.png) no-repeat 63%;
  }
  .cb-toggle-target-active > .cb-toggle {
    background-image: url(/images/cb-toggle--up.png);
  }
  .sub2 > .item > .cb-toggle,
  .item-empty > .cb-toggle {
    display: none;
  }
  .navigation .sub2 {
    display: none;
  }
  .cb-toggle-target-active > .sub2 {
    display: block;
  }
  div.sub2 .menu {
    color: #999;
    line-height: 1.1;
    padding: 0.37em 5px 0.37em 46px;
  }
  div.sub2 .menu:hover,
  div.sub2 .menu:focus,
  div.sub2 .path {
    color: #fff;
  }
}
.cb-mood--around {
  margin-top: 15px;
}
/*
two targets get mood:
cb-mood--around : includes area north (goes around it)
cb-mood--above  : is separate, above area north (only used in small)
*/
@media (max-width: 899px) {
  .cb-page-layout1 .cb-mood--around {
    background: none !important;
  }
}
.cb-mood--above {
  padding-bottom: 87.5%;
  padding-bottom: 103.125%;
}
@media (min-width: 408px) {
  .cb-mood--above {
    /*
    at this point mood reaches 420px padding bottom
    */
    padding: 0;
    height: 420px;
  }
}
.cb-page-layout6 .cb-mood--above {
  height: 0;
  padding-bottom: 51.83333333%;
}
.cb-page-title {
  margin-top: 22px;
}
h1 {
  font-size: 28px;
  font-size: 2.8rem;
}
.cb-page-layout1 h1 {
  /* smaller font needed because of smaller screens */
  font-size: 16px;
  font-size: 1.6rem;
}
#external {
  max-width: 232px;
  margin: 40px auto;
}
@media (min-width: 400px) {
  #external {
    max-width: 331px;
  }
}
@media (max-width: 679px) {
  /* 
  float to align
  */
  .service_external_confiseur,
  .service_external_prosper,
  .service_external_specialiste,
  .service_external_lehrbetrieb {
    width: 50%;
    max-width: 50%;
    float: left;
    margin-bottom: 10%;
  }
}
.service_external_ewb {
  max-width: 100%;
  width: 232px;
  background-size: 100%;
}
.cb-page-selected-sub1 .service_external_ewb,
.cb-page-selected-sub2 .service_external_ewb,
.cb-page-selected-sub3 .service_external_ewb {
  background-image: url(/images/external_ewb-small-232.png);
}
.service_external_ewb:hover,
.service_external_ewb:focus,
.aside:hover .service_external_ewb:after {
  background-image: url(/images/external_ewb---hover-small-232.png);
}
/*
aside is not in page context, therefore cb-pages has margins
*/
.footer {
  text-align: center;
  /*
  font-size & line-height for vcard,
  overwritten in medium, where it counts
  for sub2 (watch menu padding & line height there)
  */
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.25;
}
.footer .navi {
  display: none;
}
.vcard-h2 {
  display: none;
}
.copyright {
  margin-top: 40px;
  padding-top: 60px;
}
.artos-login {
  display: block;
  margin-top: 0.4em;
}
.meta.firm {
  margin: 0 6px 0 8px;
}
.area,
.north.north.north {
  width: 100%;
}
.area > .unit,
.north.north.north > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.north.north.north h2,
.area .foot,
.north.north.north .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.north.north.north .part,
.area > .grid table,
.north.north.north > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part,
.north.north.north > .slim .part,
.area > .slim.grid table,
.north.north.north > .slim.grid table {
  width: 100%;
}
.cb-page-layout4 .side,
.cb-page-layout4 .base {
  width: 100%;
}
.cb-page-layout4 .side > .unit,
.cb-page-layout4 .base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout4 .side h2,
.cb-page-layout4 .base h2,
.cb-page-layout4 .side .foot,
.cb-page-layout4 .base .foot {
  margin-right: 5.5%;
  margin-left: 5.5%;
}
.cb-page-layout4 .side .part,
.cb-page-layout4 .base .part,
.cb-page-layout4 .side > .grid table,
.cb-page-layout4 .base > .grid table {
  margin-right: 5.5%;
  margin-left: 5.5%;
  width: 89%;
}
.cb-page-layout4 .side > .slim .part,
.cb-page-layout4 .base > .slim .part,
.cb-page-layout4 .side > .slim.grid table,
.cb-page-layout4 .base > .slim.grid table {
  width: 89%;
}
.cb-page-layout6 .side {
  width: 100%;
}
.cb-page-layout6 .side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout6 .side h2,
.cb-page-layout6 .side .foot {
  margin-right: 5.5%;
  margin-left: 5.5%;
}
.cb-page-layout6 .side .part,
.cb-page-layout6 .side > .grid table {
  margin-right: 5.5%;
  margin-left: 5.5%;
  width: 89%;
}
.cb-page-layout6 .side > .slim .part,
.cb-page-layout6 .side > .slim.grid table {
  width: 89%;
}
.cb-page-layout2 .main {
  margin-top: 26px;
}
.cb-page-layout6 .main {
  padding-bottom: 40px;
}
#edit .north {
  border-bottom: 2px dashed #ccc;
}
.cb-page-layout6 .side {
  margin-top: 40px;
}
.shopright {
  display: none;
}
.main > .wide h2 {
  font-size: 18px;
  font-size: 1.8rem;
}
@media (max-width: 679px) {
  .cb-page-layout1 .unit {
    text-align: center;
    line-height: 1.25;
  }
  .unit h3 {
    font-size: 16px;
    font-size: 1.6rem;
  }
}
.cb-page-layout2 .main > .unit h2 {
  text-align: center;
  font-size: 28px;
  font-size: 2.8rem;
}
@media (max-width: 679px) {
  .cb-page-layout1 .main > .seam.wide,
  .cb-page-layout2 .main > .seam.wide,
  .flat.wide,
  .cb-page-layout1 .main > .edge.wide,
  .north > .unit {
    margin-left: -5.55555556%;
  }
}
@media (min-width: 480px) {
  .cb-page-layout1 .main > .seam.wide,
  .cb-page-layout2 .main > .seam.wide,
  .flat.wide,
  .cb-page-layout1 .main > .edge.wide,
  .north > .unit {
    margin-left: calc((100vw - 432px) / -2);
  }
}
@media (max-width: 679px) {
  .cb-page-layout1 .main > .seam.wide h2,
  .cb-page-layout2 .main > .seam.wide h2,
  .flat.wide h2,
  .cb-page-layout1 .main > .edge.wide h2,
  .cb-page-layout1 .main > .seam.wide .body,
  .cb-page-layout2 .main > .seam.wide .body,
  .flat.wide .body,
  .cb-page-layout1 .main > .edge.wide .body,
  .cb-page-layout1 .main > .seam.wide .foot,
  .cb-page-layout2 .main > .seam.wide .foot,
  .flat.wide .foot,
  .cb-page-layout1 .main > .edge.wide .foot {
    margin-right: 5%;
    margin-left: 5%;
    width: 90%;
  }
}
.cb-page-layout1 .main > .seam.wide h2,
.cb-page-layout2 .main > .seam.wide h2,
.flat.wide h2,
.cb-page-layout1 .main > .edge.wide h2,
.north h2 {
  font-size: 28px;
  font-size: 2.8rem;
}
.cb-page-layout1 .main > .seam.wide,
.cb-page-layout2 .main > .seam.wide {
  padding-bottom: 52px;
  border-bottom-width: 36px;
  margin-bottom: 40px;
}
@media (max-width: 679px) {
  /* MQ ist feige */
  .main > .seam.wide {
    background-position: 48% 100% !important;
  }
  .main > .seam.wide h2 {
    margin-top: 10px;
    font-size: 18px !important;
    font-size: 1.8rem !important;
  }
}
.main > .flat.wide {
  padding-top: 10px;
  padding-bottom: 75px;
  margin-bottom: 111px;
  margin-bottom: 31px;
}
.main > .flat .loud {
  line-height: 1.3;
}
@media (max-width: 679px) {
  /* MQ ist feige */
  .main > .flat.wide {
    line-height: 1.3;
  }
  .main > .flat .loud {
    padding-top: 0.35em;
  }
  .main > .flat .link {
    margin-top: 2.7em;
  }
  .main > .flat .link > .open {
    font-size: 12px;
    font-size: 1.2rem;
    padding: 1em 1.3em;
  }
}
/*
dont over-size heading
-> maybe extend to all full oversized units
*/
/*
dont over-size body text
-> maybe extend to all full oversized units
*/
.main > .flat.wide h2 {
  /* 
Layout 2 small
*/
  margin-top: 1.9em;
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.36363636;
  letter-spacing: 0.027em;
}
.cb-page-layout1 .main > .edge.wide h2 {
  font-size: 28px;
  font-size: 2.8rem;
  margin-top: 24px;
  line-height: 1.1;
}
.cb-page-layout1 .main > .edge.wide h3 {
  line-height: 1.04;
  font-size: 24px;
  font-size: 2.4rem;
}
.cb-page-layout4 .side > .unit,
.cb-page-layout4 .base > .unit {
  font-size: 18px;
  font-size: 1.8rem;
}
.north > .unit {
  background: #471919;
}
.north h2 {
  margin-top: 17px;
  line-height: 1.1;
  letter-spacing: 0.02em;
}
.north h3 {
  font-size: 24px;
  font-size: 2.4rem;
}
.north .lead + .link {
  margin-top: 30px;
}
.north .link > .open {
  font-size: 12px;
  font-size: 1.2rem;
  padding: 1em 1.4em 0.8em 1.4em;
  letter-spacing: 0.1em;
}
@media (max-width: 679px) {
  .cb-page-layout1 .north .body {
    width: 94%;
    margin-left: 3%;
  }
}
.cb-page-layout2 .north h2 {
  margin-top: 0.5em;
  margin-bottom: 0.9em;
}
.north.north h2,
.cb-page-layout1 .main > .edge.wide h2 {
  margin-bottom: 8px !important;
  line-height: 1.2;
}
.cb-page-layout3 .main h3 {
  font-size: 18px;
  font-size: 1.8rem;
}
.cb-page-layout3 .main > .edge h3 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.2;
}
#mc_embed_signup .mc-field-group,
#mc_embed_signup .button {
  display: block;
  width: 100%;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
#disp.cb-shoppingcart {
  left: 10%;
  margin-left: 0;
  width: 80%;
  min-width: 340px;
}
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/******* module-shop.less 2013-1-16 *******/
input.prix {
  float: left;
  margin-right: 2px;
  width: 45px;
}
.cury {
  /* was div.payp span.cury, check shop lightbox */
  margin-left: 4px;
}
.payp input.numb {
  font-size: 14px;
  font-size: 1.4rem;
  float: left;
  margin-right: 5px;
  width: 2em !important;
  text-align: right;
}
.payp .ctrl > div,
.ctrl > .payp {
  display: inline-block;
  vertical-align: middle;
}
.ctrl > .payp {
  top: 0.1em;
  position: relative;
  padding: 0 0.5em;
}
.payp .name,
.payp .ctrl {
  padding-bottom: 0.5em;
}
.payp .name {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
.payp.tile,
.payp.tile .papc {
  position: relative;
}
.payp.tile .papc {
  padding-bottom: 2em;
  margin-bottom: -2em;
}
.payp.tile input.numb {
  bottom: 0.4em;
  position: absolute;
}
.payp .ctrl {
  float: right;
  text-align: right;
}
.payp input.payp {
  padding: 0;
  width: 29px;
  height: 29px;
  border: 0;
  background: url('/images/input-payp.png') no-repeat;
}
.payp input.payp:hover {
  background-position: 0px -32px;
  background-color: transparent;
}
.payp input.success {
  background-position: 0px -64px;
}
.payp input.success:hover {
  background-position: 0px -96px;
}
.payp input.fade,
.payp input.fade:hover {
  background-position: 0px -128px;
}
#root div.payp textarea {
  resize: none;
}
.cb-price-comment,
.cb-price-file,
.cb-price-file input {
  float: left;
  margin-top: 0.2em;
  width: 100%;
}
.cb-price-comment > textarea {
  width: 100%;
}
.cb-price-comment > .hint {
  color: #808080;
}
#disp.shop .name,
a.agbs,
.cb-shoppingcart .cb-shop-country > label {
  float: left;
  padding-right: 1%;
}
.cb-shop-country {
  padding-bottom: 2em;
}
.cb-shop-country > label {
  width: 31%;
}
#disp .cb-shop-country > select {
  width: 68%;
}
#disp.shop textarea {
  height: inherit;
}
a.agbs {
  overflow: hidden;
  margin-bottom: 5px;
  background: url('/icon-small/pdf.gif') no-repeat 2px 4px;
  text-indent: 15px;
  text-overflow: ellipsis;
}
.cb-agb > fieldset {
  float: left;
  margin-bottom: 10px;
}
.cb-shop-tab-contents h3,
.cb-shoppingcart h3 {
  clear: both;
  margin: 14px 0 5px;
  padding: 0.2em 5px;
  border-bottom: 1px solid #aaa;
  background-color: #f5f5f5;
  color: #333;
  letter-spacing: 0.02em;
  font-size: 1.1em;
  line-height: 1.25;
}
#disp.shop .foot {
  padding: 10px 0;
  border-top: 1px solid #aaa;
}
.cb-shop-tabs {
  margin: 10px 0 -14px;
  border-bottom: 1px solid #aaa;
}
.cb-shop-tabs > li {
  display: block;
}
.cb-shop-tab {
  display: block;
  padding: 0.3em 0.4em;
  border: 1px solid #aaa;
  border-bottom-width: 0;
  background: #ddd;
  text-decoration: none;
}
.cb-shop-tab.selected {
  background-color: #f5f5f5;
}
.cb-shop-tab-contents > .cb-shop-tab-contents {
  display: none;
}
.cb-shop-tab-contents > .selected {
  display: block;
}
#disp.shop .name,
a.agbs,
.cb-shop-country > label {
  min-height: 1px;
}
#disp.shop .name,
a.agbs {
  width: 59%;
  width: 100%;
}
#disp.shop textarea {
  width: 100%;
}
.shop .file,
.cb-shoppingcart .file,
.shop select,
.cb-shoppingcart select {
  float: left;
}
.cb-shop-exchange-rate-equal {
  float: left;
}
.cb-shop-exchange-rate {
  margin-bottom: 8px;
}
.cb-shop-currency,
.cb-shop-countries,
.cb-shop-regions {
  position: relative;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a,
.cb-shop-countries + fieldset > .name + a,
.cb-shop-regions + fieldset > .name + a {
  display: inline-block;
  text-decoration: underline;
}
.cb-shop-vat > .cury {
  float: left;
}
#disp.shop .zip {
  float: left;
  clear: left;
  margin-right: 1%;
  width: 18%;
  text-align: right;
}
#disp.shop .cb-shop-currency > select,
#disp.shop .cb-shop-countries > select,
#disp.shop .cb-shop-regions > select {
  width: 90%;
}
#disp.shop input.city {
  width: 80%;
}
.cb-shop-currency + fieldset > .name + a,
.cb-shop-vat + fieldset > .name + a,
.cb-shop-countries + fieldset > .name + a,
.cb-shop-regions + fieldset > .name + a {
  clear: left;
}
#cb-footnotes {
  margin: 10px 0;
  color: #808080;
  text-align: center;
}
.cb-shoppingcart table {
  border-collapse: collapse;
}
.cb-shoppingcart th,
.cb-shoppingcart td {
  padding: 4px 3px;
}
.cb-shoppingcart th {
  border-bottom: 2px solid #666;
  color: #222;
  text-align: left;
}
.cb-shoppingcart .hdpr {
  text-align: right;
}
td.hdpr,
.cb-cart-subtotal-vat-incl > .cb-cart-label,
.cb-cart-subtotal-vat-excl > .cb-cart-label {
  white-space: nowrap;
}
#items > .odd {
  background: none;
}
.cb-shoppingcart td {
  border-bottom: 1px solid #aaa;
  vertical-align: middle;
}
.cb-total-without-vat > td,
.cb-total-with-vat > td {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  border: 2px solid #666;
  border-style: solid none;
  background-color: #f5f5f5;
  color: #222;
}
.cost {
  text-align: right;
}
.remo {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 18px;
  height: 18px;
  margin-top: 0.22em;
  background: url('/icons/cross.png') no-repeat;
  position: relative;
  top: 5px;
}
.remo:hover,
.remo:focus {
  background-position: 0 -22px;
}
.remo:active {
  background-position: 0 -47px;
}
div.cb-payment .radio {
  margin-right: 4px;
}
div.cb-payment div.fail {
  float: none;
}
div.cb-shoppingcart div.ship {
  margin-right: 0;
}
#disp .cb-additional-information textarea {
  overflow: scroll;
  /* module shop */
  height: auto;
}
#disp fieldset.last {
  margin: 0;
}
.cb-address,
.cb-additional-information,
.cb-payment,
.cb-order,
.cb-sandbox,
.cb-agb,
.cb-shop-country {
  float: left;
  width: 100%;
}
.cb-shoppingcart .horz {
  float: left;
  width: 100%;
}
.cb-shoppingcart .cb-shipping-address-option {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
  clear: both;
  margin: 10px 0;
  padding: 2px 0;
  border: 1px solid #808080;
  background-color: #ddd;
  color: #222;
}
div.cb-shoppingcart div.cb-address p.cb-notice {
  margin-top: 7px;
  color: #808080;
}
.cb-address > a {
  font-size: 12px;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.11666667em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #ab0241 #78012e #78012e #ab0241;
  border-radius: 2px;
  background-color: #c4024b;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(95, 1, 36, 0.75);
  line-height: 2.2em;
  float: left;
  clear: left;
  margin: 20px 0;
}
.cb-address > a:hover,
.cb-address > a:focus {
  border-color: #ab0241 #78012e #78012e #ab0241;
  background-color: #d30251;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.cb-address > a:active {
  outline: none;
  border-color: #ab0241 #78012e #78012e #ab0241;
  background-color: #920138;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
#disp.cb-shoppingcart input.numb {
  font-size: 14px;
  font-size: 1.4rem;
  margin: 0;
  width: 30px;
  text-align: right;
}
div.cb-shoppingcart a.agbs {
  margin-right: 10px;
}
.cb-shoppingcart fieldset > .fail {
  /* overwrites div.fail */
  float: none;
}
div.cb-shoppingcart input.cb-hybrid {
  margin-left: 5px;
}
div.cb-payment input.cb-hybrid {
  margin: 7px 0 0 0;
}
#disp.shop input.conf,
.cb-shoppingcart .next {
  float: right;
}
.cb-shoppingcart .next {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
}
.foot > fieldset > form {
  float: left;
}
.foot > fieldset > form + form {
  float: right;
}
.cb-payment-prepay-info {
  margin: 0.7em 0;
  list-style: none;
}
.cb-payment-prepay-info-line {
  padding-left: 1em;
  min-height: 0.75em;
}
label.tiny,
label.tall {
  font-weight: normal;
  font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
}
#disp label.tiny {
  float: left;
  width: 20%;
}
#disp p.tiny {
  float: left;
  width: 80%;
}
#disp select.tiny {
  float: left;
  width: 80px !important;
}
#disp.shop .cb-shop-exchange-rate > .tiny {
  float: left;
  margin: 0 5px 0 10px;
  width: 4em !important;
  text-align: right;
}
#disp .tiny > label.name {
  width: 100%;
}
#disp.shop input.tiny {
  float: left;
  width: 5em;
  text-align: right;
}
.cb-shop-shipping-definition a {
  float: left;
}
.cb-shop-shipping-definition .remo {
  margin-top: 5px;
}
.cb-shop-shipping-definition-details {
  float: left;
  width: 100% !important;
}
.cb-shipping-definitions-tabs {
  float: left;
  clear: left;
  margin: 0 0 1em;
  width: 32%;
  background: #c6c6c6;
}
.cb-shipping-definitions-tabs > li {
  list-style: none;
}
.cb-shipping-definition-tab {
  display: block;
  padding: 0.5em 0.8em;
  border-right: 1px solid #aaa;
  text-decoration: none;
  font-size: 0.9em;
  line-height: 1.2;
}
.cb-shipping-definition-tab:hover,
.cb-shipping-definition-tab:focus {
  border-color: #c6c6c6;
  background: #f5f5f5;
}
.cb-shipping-definition-selected > .cb-shipping-definition-tab {
  border-color: #fff;
  background: #fff;
}
.cb-shipping-definitions-tab-contents {
  float: right;
  width: 67%;
  list-style: none;
}
.cb-shipping-ranges {
  width: 100%;
  table-layout: fixed;
}
.cb-shipping-range > th {
  text-align: left;
}
.cb-shipping-range-label,
.cb-shipping-range-value {
  width: 40%;
}
.cb-shipping-range-action {
  width: 20%;
}
.cb-shipping-definition-contents {
  display: none;
}
.cb-shipping-definition-selected > .cb-shipping-definition-contents {
  display: block;
}
.cb-shipping-definition-contents td {
  padding: 1px;
  vertical-align: middle;
}
@media screen and (max-width: 680px) {
  .cb-shoppingcart table,
  .cb-shoppingcart thead,
  .cb-shoppingcart tbody,
  .cb-shoppingcart th,
  .cb-shoppingcart td,
  .cb-shoppingcart tr {
    display: block;
  }
  .cb-shoppingcart thead {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
  }
  .cb-shoppingcart th {
    border: 0;
    padding: 0;
  }
  .cb-shoppingcart .cb-cart-item {
    margin-bottom: 2em;
  }
  .cb-shoppingcart td {
    *zoom: 1;
    position: relative;
    padding-left: 38%;
  }
  .cb-shoppingcart td:before,
  .cb-shoppingcart td:after {
    display: table;
    content: '';
  }
  .cb-shoppingcart td:after {
    clear: both;
  }
  .cb-shoppingcart td:before {
    font-weight: normal;
    font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
    position: absolute;
    top: 4px;
    left: 3px;
    white-space: nowrap;
    padding-right: 1em;
    width: 36%;
    text-align: left;
    content: attr(data-title);
  }
  .cb-shoppingcart #cb-footnotes {
    text-align: right;
    padding-left: 38%;
    line-height: 1.4;
  }
  .cb-cart-empty {
    display: none !important;
  }
  .cb-shoppingcart .cb-price-comment,
  .cb-shoppingcart .cb-price-file {
    float: none;
    display: block;
    padding-top: 0.2em;
    border-top: 1px dashed #ccc;
  }
  .cb-shoppingcart .cb-cart-item-action {
    padding-right: 0;
    border: 0;
  }
  .cb-shoppingcart .remo {
    *zoom: 1;
    display: block;
    float: right;
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 6em;
    background-image: none;
    color: #fff !important;
    text-indent: 0;
    font-size: 12px;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0.11666667em 1em;
    min-height: 2.2em;
    border: 1px solid;
    border-color: #db3a1b;
    border-radius: 2px;
    background-color: #e85c41;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 1px 0 rgba(174, 46, 21, 0.75);
    line-height: 2.2em;
  }
  .cb-shoppingcart .remo:before,
  .cb-shoppingcart .remo:after {
    display: table;
    content: '';
  }
  .cb-shoppingcart .remo:after {
    clear: both;
  }
  .cb-shoppingcart .remo:hover,
  .cb-shoppingcart .remo:focus {
    border-color: #ae2e15;
    background-color: #e5482a;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .cb-shoppingcart .remo:active {
    outline: none;
    border-color: #802210;
    background-color: #db3a1b;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
    color: #fff;
    text-shadow: none;
  }
  .cb-cart-subtotals {
    position: relative;
  }
  .cb-cart-subtotal-shipping {
    position: relative;
  }
  .cb-cart-subtotal-shipping > .cb-cart-label {
    padding: 0;
    position: absolute;
    top: 4px;
    left: 3px;
    white-space: nowrap;
    padding-right: 1em;
    width: 36%;
    border: 0;
  }
  .cb-cart-subtotal-shipping > .cb-cart-value {
    border: 0;
  }
  .cb-total-without-vat {
    background: #f5f5f5;
    position: relative;
  }
  .cb-total-without-vat > td {
    background: none;
  }
  .cb-total-without-vat > .cb-cart-label {
    font-weight: normal;
    font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
    position: absolute;
    top: 4px;
    left: 3px;
    margin-top: 2px;
    padding: 0;
    padding-right: 1em;
    width: 36%;
    border: 0;
    white-space: nowrap;
  }
  .cb-cart-subtotal-vat-excl,
  .cb-cart-subtotal-vat-incl {
    position: relative;
  }
  .cb-cart-subtotal-vat-excl > td,
  .cb-cart-subtotal-vat-incl > td {
    background: none;
  }
  .cb-cart-subtotal-vat-excl > .cb-cart-label,
  .cb-cart-subtotal-vat-incl > .cb-cart-label {
    position: absolute;
    top: 4px;
    left: 3px;
    padding: 0;
    padding-right: 1em;
    width: 36%;
    border: 0;
    white-space: nowrap;
  }
  .cb-cart-totals {
    display: block;
  }
  .cb-total-with-vat {
    position: relative;
    background: #f5f5f5;
  }
  .cb-total-with-vat > td {
    background: none;
  }
  .cb-total-with-vat > .cb-cart-label {
    font-weight: normal;
    font-family: 'ProximaNovaExtrabold', 'helvetica neue', helvetica, sans-serif;
    position: absolute;
    top: 4px;
    left: 3px;
    padding: 0;
    margin-top: 2px;
    padding-right: 1em;
    width: 36%;
    border: 0;
    white-space: nowrap;
  }
  .cb-shoppingcart #cb-footnotes {
    float: left;
    text-align: left;
    margin-top: 0.4em;
    padding: 0;
  }
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */